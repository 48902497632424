import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesSpenderPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Spender guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_spender.png"
            alt="Spender guide"
          />
        </div>
        <div className="page-details">
          <h1>Spender guide</h1>
          <h2>
            A guide for minnows, dolphins and whales that want to be efficient.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Video version" />
        <p>For those that prefer watching a video version:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="b4X4UeOYnTQ" />
          </Col>
        </Row>
        <SectionHeader title="Packs" />
        <p>
          Below you will find a few example packs available in the game. We
          assigned certain values to the Gems and Tickets and came up with a
          formula that shows how good each of those packs are. The higher the
          value in last column, the better.
        </p>
        <p>
          Keep in mind that below you won't find any of the newbie packs that
          actually{' '}
          <strong>
            have the best value in the game currently and are worth buying
            anyway if you plan to spend
          </strong>
          . The table below shows packs that are permanently in the game.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Pack</th>
              <th>Price ($)</th>
              <th>Gems</th>
              <th>Tickets</th>
              <th>SSR Shards</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>30-Day Supply</td>
              <td>4.99</td>
              <td>3300</td>
              <td>-</td>
              <td>-</td>
              <td>667</td>
            </tr>
            <tr>
              <td>Battle Pass</td>
              <td>19.99</td>
              <td>-</td>
              <td>25</td>
              <td>50</td>
              <td>600</td>
            </tr>
            <tr>
              <td>Campaign I</td>
              <td>19.99</td>
              <td>10 000</td>
              <td>-</td>
              <td>-</td>
              <td>500</td>
            </tr>
            <tr>
              <td>NIKKE Master IV</td>
              <td>99.99</td>
              <td>8 000</td>
              <td>30</td>
              <td>50</td>
              <td>215</td>
            </tr>
            <tr>
              <td>Monthly Recruitment IV</td>
              <td>99.99</td>
              <td>8 000</td>
              <td>40</td>
              <td>-</td>
              <td>200</td>
            </tr>
            <tr>
              <td>Growth Pack III</td>
              <td>99.99</td>
              <td>8 000</td>
              <td>-</td>
              <td>100</td>
              <td>170</td>
            </tr>
            <tr>
              <td>First Purchase 6200</td>
              <td>79.99</td>
              <td>12 400</td>
              <td>-</td>
              <td>-</td>
              <td>155</td>
            </tr>
            <tr>
              <td>First Purchase 2300</td>
              <td>29.99</td>
              <td>4 600</td>
              <td>-</td>
              <td>-</td>
              <td>153</td>
            </tr>
          </tbody>
        </Table>
        <p>
          As you can see, after the first 3 packs, the value drastically drops -
          the packs are simply overpriced compared to others and only good to
          buy if you are a dolphin or a whale.
        </p>
        <SectionHeader title="All packs in the game" />
        <p>
          If you want to compare the values for all packs in the game, check
          this great spreadsheet created by <strong>Rayel#0001</strong>:
        </p>
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/d/1cbMB9KQeAvL_PDT8t29ipRWB5UnfkKguVElad_DUB4s/edit#gid=0"
          target="_blank"
          rel="noreferrer"
        >
          Cash Shop Data
        </Button>
        <SectionHeader title="30-Day Supply" />
        <StaticImage
          src="../../../images/nikke/generic/30day_pack.jpg"
          alt="30 day pack"
        />
        <p>
          As usual in most games, the monthly gem pack that gives you some gems
          upfront and then daily for 30 days (3300 in total) is the best thing
          you can buy.
        </p>
        <p>
          Ignore the high price - it's my local currency. The $ price can be
          found in the table above.
        </p>
        <SectionHeader title="Battle Pass" />
        <StaticImage
          src="../../../images/nikke/generic/nikke_bp.jpg"
          alt="Battle Pass"
        />
        <p>
          The Battle Pass costs $19.99 in NIKKE and while initially we thought
          it was a scam, after comparing it to other packs, it's actually one of
          the better things to buy if you look at the Gems and Shards only. It
          also comes with a skin - a different one every season.
        </p>
        <p>Full list of content:</p>
        <ul>
          <li>1 skin</li>
          <li>25 Regular Tickets</li>
          <li>
            50 SSR Shards (60% to obtain SSR character, so it's a bit RNG)
          </li>
        </ul>
        <SectionHeader title="Campaign I" />
        <StaticImage
          src="../../../images/nikke/generic/campaign_pack_f.jpg"
          alt="Campaign Pack"
        />
        <p>
          Along the Monthly Gems packs, the first-time-clear packs are another
          staple of the gacha genre. In NIKKE while the number of gems received
          here is lower compared to other idle games (here you get 800 gems per
          chapter and most other games gives you enough for a 10-pull), you have
          to keep in mind that NIKKE requires only 3 dupes to max limit break a
          character. Anything after you reach MLB will just provide you with 2%
          stat bonus per dupe.
        </p>
        <p>In total, for clearing up to Chapter 10, you receive 10k Gems.</p>
        <SectionHeader title="NIKKE Master IV" />
        <StaticImage
          src="../../../images/nikke/generic/nikke_pack.jpg"
          alt="Nikke Pack"
        />
        <p>
          This pack on top of the usual Gems and Tickets, also gives you a T9
          gear selector that will give a big power boost to one of your
          characters, especially in the early game.
        </p>
        <p>It's a great pack for dolphins and whales to get a head start.</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesSpenderPage;

export const Head: React.FC = () => (
  <Seo
    title="Spender guide | NIKKE | Prydwen Institute"
    description="A guide for minnows, dolphins and whales that want to be efficient."
  />
);
